import { getLocaleDateString } from '@komo-tech/core/utils/date';
import { ChangeEvent, forwardRef, useMemo } from 'react';
import InputMask from 'react-input-mask';

import { useUncontrolled } from '../../../hooks/useUncontrolled';
import { FormTextInput, FormTextInputProps } from '../TextInput';
import parse from 'date-fns/parse';

type Value = { value: string; dateFormat: string; dateValue?: Date };

export interface FormDateMaskInputProps
  extends Omit<
    FormTextInputProps,
    'placeholder' | 'sanitiseValue' | 'inputMode' | 'onChange' | 'onBlur'
  > {
  onChange?: (e: ChangeEvent<HTMLInputElement>, value: Value) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>, value: Value) => void;
  locale?: string;
}

export const FormDateMaskInput = forwardRef<
  HTMLInputElement,
  FormDateMaskInputProps
>(
  (
    {
      value: valueProp,
      defaultValue,
      disabled,
      onChange,
      onBlur,
      locale,
      ...rest
    },
    ref
  ) => {
    const dateFormat = useMemo(() => getLocaleDateString(locale), [locale]);

    const [value, handleChange] = useUncontrolled({
      value: valueProp,
      defaultValue: defaultValue,
      finalValue: ''
    });

    return (
      <InputMask
        mask={dateFormat.replace(/[dMy]/g, '9')}
        maskChar={null}
        value={value}
        onChange={(e) => {
          const rawValue = e.target.value;
          handleChange(rawValue);
          let date = parseDate(rawValue, dateFormat);
          if (!isValidDate(date)) {
            date = null;
          }
          onChange?.(e, {
            value: rawValue,
            dateFormat,
            dateValue: date
          });
        }}
        disabled={disabled}
        onBlur={(e) => {
          let date = parseDate(e.target.value, dateFormat);
          if (!isValidDate(date)) {
            date = null;
          }
          onBlur?.(e, {
            value: e.target.value,
            dateFormat,
            dateValue: date
          });
        }}
        placeholder={dateFormat.toUpperCase()}
        alwaysShowMask
      >
        {(maskProps) => (
          <FormTextInput
            ref={ref}
            {...rest}
            disabled={disabled}
            {...maskProps}
            inputMode="numeric"
          />
        )}
      </InputMask>
    );
  }
);

const parseDate = (date: string, locale: string) => {
  return parse(date, locale, new Date());
};

function isValidDate(d: any) {
  return !isNaN(d) && d instanceof Date;
}
