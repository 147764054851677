import {
  ElementProps,
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps
} from '@mantine/core';
import isNil from 'lodash/isNil';
import { forwardRef } from 'react';

import { useUncontrolled } from '../../../hooks/useUncontrolled';
import {
  FormInputWrapper,
  IAmFormInputRenderProps
} from '../InputWrapper/FormInputWrapper';

export interface FormTextInputProps
  extends Omit<
      MantineTextInputProps,
      | 'defaultValue'
      | 'type'
      | 'value'
      | 'onChange'
      | 'onBlur'
      | 'labelProps'
      | 'descriptionProps'
      | 'withAsterisk'
      | 'color'
      | 'size'
    >,
    IAmFormInputRenderProps<HTMLInputElement> {
  /** Input element type */
  type?: 'hidden' | 'email' | 'search' | 'text' | 'tel' | 'url' | 'password';
  defaultValue?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  sanitiseValue?: (value: string) => string;
  withAsterisk?: boolean;

  /**
   * @deprecated use leftSection instead
   */
  icon?: MantineTextInputProps['leftSection'];
  /**
   * @deprecated use leftSectionWidth instead
   */
  iconWidth?: MantineTextInputProps['leftSectionWidth'];

  /**
   * @deprecated use c instead
   */
  color?: ElementProps<'div'>['color'];
}

export const FormTextInput = forwardRef<HTMLInputElement, FormTextInputProps>(
  (
    {
      value: valueProp,
      defaultValue,
      size,
      sanitiseValue,
      onChange,
      onBlur,
      required,
      onFocus,
      selectAllTextOnFocus,
      autoFocus,
      inputContainer,
      labelProps,
      descriptionProps,
      error,
      hideErrorMessage,
      icon,
      iconWidth,
      leftSection: leftSectionProp,
      leftSectionWidth: leftSectionWidthProp,
      withAsterisk,
      showSkeleton,
      ...rest
    },
    ref
  ) => {
    const [value, handleChange] = useUncontrolled<
      string,
      React.ChangeEvent<HTMLInputElement>
    >({
      value: valueProp,
      defaultValue,
      finalValue: null,
      resolveValue: (e) => e.target.value,
      sanitiseValue,
      onChange
    });

    const leftSection = leftSectionProp ?? icon;
    let leftSectionWidth = leftSectionWidthProp ?? iconWidth;
    if (!!leftSection && isNil(leftSectionWidth) && size === 'lg') {
      leftSectionWidth = 40;
    }

    return (
      <FormInputWrapper<HTMLInputElement>
        ref={ref}
        autoFocus={autoFocus}
        onFocus={onFocus}
        selectAllTextOnFocus={selectAllTextOnFocus}
        labelProps={labelProps}
        inputContainer={inputContainer}
        descriptionProps={descriptionProps}
        error={error}
        size={size}
        hideErrorMessage={hideErrorMessage}
        showSkeleton={showSkeleton}
      >
        {(p, r) => (
          <MantineTextInput
            ref={r}
            value={value}
            onChange={handleChange}
            onBlur={!!onBlur ? (e) => onBlur(e, e.target.value) : undefined}
            withAsterisk={!isNil(withAsterisk) ? withAsterisk : required}
            leftSectionWidth={leftSectionWidth}
            leftSection={leftSection}
            {...rest}
            {...p}
          />
        )}
      </FormInputWrapper>
    );
  }
);
